import React, { useEffect } from 'react';
import { Navbar, Nav, NavItem, NavLink, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faBars, faUserShield, faCopy, faHome } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useHistory } from 'react-router-dom';

const AdminNavbar = ({ environment, onEnvironmentChange, onCopyProfiles, navTitle, id }) => {
    const location = useLocation();
    const history = useHistory();

    const handleNavigation = (path) => {
        history.push(`/h1f2d1i1d2b/${path}?id=${id}`);
    };
    const handleBack = () => {
        history.push(`/h1f2d1i1d2b`)

    }
    useEffect(() => {
        sessionStorage.setItem('environment', environment);
    }, [environment]);
    return (
        <Navbar className="navbarCustom" expand="md" style={{ padding: '10px 20px' }}>
            <Nav navbar>
                <NavItem>
                    <div className="navLinkTooltip">
                        <NavLink
                            to="profiles"
                            onClick={() => handleBack()}
                            className={location.pathname.includes("profiles") ? "active navLeft" : "navLeft"}
                            style={{ cursor: 'pointer' }}
                        >
                             <FontAwesomeIcon icon={faHome} className="iconCustom" />
                        </NavLink>
                        <span className="tooltipText">Aplicações</span>
                    </div>
                </NavItem>
                <NavItem>
                    <div className="navLinkTooltip">
                        <NavLink
                            to="profiles"
                            onClick={() => handleNavigation("profiles")}
                            className={location.pathname.includes("profiles") ? "active navMiddle" : "navMiddle"}
                            style={{ cursor: 'pointer' }}
                        >
                            <FontAwesomeIcon icon={faAddressCard} className="iconCustom" />
                        </NavLink>
                        <span className="tooltipText">Perfis</span>
                    </div>
                </NavItem>
                <NavItem>
                    <div className="navLinkTooltip">
                        <NavLink
                            to="menu"
                            onClick={() => handleNavigation("menu")}
                            className={location.pathname.includes("menu") ? "active navMiddle" : "navMiddle"}
                            style={{ cursor: 'pointer' }}
                        >
                            <FontAwesomeIcon icon={faBars} className="iconCustom" />
                        </NavLink>
                        <span className="tooltipText">Menu</span>
                    </div>
                </NavItem>
                <NavItem>
                    <div className="navLinkTooltip">
                        <NavLink
                            to="users"
                            onClick={() => handleNavigation("users")}
                            className={location.pathname.includes("users") ? "active navRight" : "navRight"}
                            style={{ cursor: 'pointer' }}
                        >
                            <FontAwesomeIcon icon={faUserShield} className="iconCustom" />
                        </NavLink>
                        <span className="tooltipText">Utilizadores</span>
                    </div>
                </NavItem>

            </Nav>
            <Nav className="ml-auto" style={{ textAlign:"center" } }>
                <Input
                    
                    type="select"
                    id="ddlEnvironment"
                    value={environment}
                    onChange={(e) => onEnvironmentChange(e.target.value)}
                    style={{ width: "auto" }}
                >
                    <option value="1">Produção</option>
                    <option value="2">Qualidade</option>
                    <option value="3">Desenvolvimento</option>
                </Input>
                <Button className="iconCopy"
                    color="primary"
                    onClick={onCopyProfiles}
                >
                    <FontAwesomeIcon icon={faCopy} />
                </Button>
            </Nav>
        </Navbar>
    );
};

export default AdminNavbar;
