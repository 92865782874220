import React, { Component } from "react"
import { connect } from "react-redux"
import { GetParameter, Toast, ToastTypes, Loading } from "../components/global/Utils"
import { faAddressCard, faBars, faEdit, faUserShield, faPlus, faSave, faSync, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import McdTable from "../components/global/Table"
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Row, Col, Input, Label } from 'reactstrap'
import { Link } from "react-router-dom"
import { v4 as uuidv4, validate as uuidValidate } from 'uuid'

class Applications extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isAuth: false,
            profileId: 0,
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            applications: [],
            modal: {
                isOpen: false, isNew: true,
                appId: "", externalAppId: "", appName: "", issuer: "", subject: "AccessToken", audience: "default", consumerUrl: "https://", debugConsumerUrl: "https://", qualityConsumerUrl: "https://",
                userIdentification: "UserIdentifier", accessTokenExpiration: false, logoutUrl: "https://", debugLogoutUrl: "https://", qualityLogoutUrl: "https://", showAlert: false, alertMsg: "", alertType: ToastTypes.Info
            },
            modalRefreshCache: { isOpen: false, env: null, appId: null, appName:"" },
            tableEnvironments: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: [],
                liveSearch: true 
            },
            environments: [],
            modalEnvironment: {
                isOpen: false, appId: "", envId: 0, envTypeId: 1, version: 2, url: "", alertType: ToastTypes.Info, showAlert: false, alertMsg: ""
            }
        }

        this.handleSaveApplication = this.handleSaveApplication.bind(this)
        this.handleNewAppId = this.handleNewAppId.bind(this)
        this.handleNewSecret = this.handleNewSecret.bind(this)
        this.handleMenu = this.handleMenu.bind(this)
        this.handleRefreshAppCache = this.handleRefreshAppCache.bind(this)
        this.RefreshAppCache = this.RefreshAppCache.bind(this)
        this.handleCloseModalAlert = this.handleCloseModalAlert.bind(this)

        this.handleNewEnvironment = this.handleNewEnvironment.bind(this)
        this.handleSaveEnvironment = this.handleSaveEnvironment.bind(this)
        this.handleCloseModalEnvironmentAlert = this.handleCloseModalEnvironmentAlert.bind(this)
    }

    componentDidMount() {
        // check if access_token exists
        if (this.props.access_token === null || this.props.access_token === undefined || sessionStorage.getItem("access_token")===null) {
            if (document.location.pathname.toLowerCase().startsWith("/h1f2d1i1d2b/callback")) {
                const code = GetParameter("code")

                if (code !== null) {
                    // Get access_token with refresh_token
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(code)
                    }

                    fetch("/h1f2d1i1d2b/auth/authorize", requestOptions).then(response => response.json())
                        .then(json => {
                            if (json === null)
                                return

                            // Remove the code from url
                            window.history.replaceState(null, null, "/h1f2d1i1d2b")
                            sessionStorage.setItem("access_token",json)
                            this.props.dispatch({ type: "ACCESS_TOKEN", payload: json })
                            this._componentDidMount()
                        })
                        .catch(e => console.log("app-fetch-01", e))
                }
            }
            else {
                // Get access_token with refresh_token
                fetch("/h1f2d1i1d2b/auth/refresh")
                    .then(response => {
                        if (response.status === 200)
                            return response.json()

                        sessionStorage.clear("access_token");

                        // don't have refresh token, need authentication
                        if (response.status === 302)
                            response.text().then(url => document.location.href = url)
                        return null
                    })
                    .then(json => {
                        if (json === null)
                            return
                        sessionStorage.setItem("access_token", json)
                        this.props.dispatch({ type: "ACCESS_TOKEN", payload: json })
                        this._componentDidMount()
                    })
                    .catch(e => console.log("app-fetch-02", e))
            }

            return
        }

        // all ok
        this._componentDidMount()
    }

    _componentDidMount() {
        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/h1f2d1i1d2b/admin/applications", requestOptions)
            .then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    isAuth: true,
                    applications: json,
                    table: {
                        title: "Aplicações",
                        columns: {
                            title: ["AppId", "Nome", "Refresh", ""],
                            width: [20, 55, 5, 10],
                            align: ["left", "left", "center", "right"],
                            search: [true, true, false, false]
                        },
                        rows: this.buildGridRows(json),
                        pagination: true,
                        actions: [
                            { action: () => this.handleNewApplication(), icon: faPlus, title: "Nova aplicação" }
                        ]
                    }
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => {
                this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false))
                console.log("HHHHHHHHHHHH" + sessionStorage.getItem("access_token"));
                sessionStorage.clear("access_token");            }

        )
    }

    buildGridRows(json) {
        return json.map(row => {
            return {
                id: row.appId,
                columns: [
                    { column: row.appId },
                    { column: row.appName },
                    { column: faSync, color: "#dc3545", action: () => this.handleRefreshAppCache(row.appId), tooltip: "Refresh" }
                ],
                actions: [
                    { column: faEdit, action: () => this.handleEditApplicaton(row.appId), tooltip: "Editar" },
                    { column: faAddressCard, action: (e) => this.handleProfiles(e, row.appId), tooltip: "Perfis" },
                    { column: faBars, action: (e) => this.handleMenu(e, row.appId), tooltip: "Menu" },
                    { column: faUserShield, action: (e) => this.handleUsers(e, row.appId), tooltip: "Utilizadores" }
                ]
            }
        })
    }

    buildGridEnvironmentRows(json) {
        return json.map(row => {
            return {
                id: row.environmentId,
                columns: [
                    { column: this.labelEnvironment(row.environmentTypeId) },
                    { column: row.environmentUrl },
                    { column: this.labelVersion(row.version) }
                ],
                actions: [
                    { column: faEdit, action: () => this.handleEditEnvironment(row.environmentId, row.appId), tooltip: "Editar" },
                    { column: faTrash, action: () => this.handleDelEnvironment(row.environmentId, row.appId), tooltip: "Apagar" }
                ]
            }
        })
    }

    toggle = () => {
        this.setState(prevState => { return { modal: { ...prevState.modal, isOpen: !prevState.modal.isOpen } } })
    }

    toggleEnvironment = () => {
        this.setState(prevState => { return { modalEnvironment: { ...prevState.modalEnvironment, isOpen: !prevState.modalEnvironment.isOpen } } })
    }

    handleNewApplication() {
        this.setState({
            tableEnvironments: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: [],
                liveSearch: true
            },
            modal: {
                isOpen: true, isNew: true,
                appId: "", appName: "", issuer: "", subject: "AccessToken", audience: "default",
                userIdentification: "UserIdentifier", accessTokenExpiration: false,
                secret: "", showAlert: false, alertMsg: "", alertType: ToastTypes.Info
            }
        })
    }

    handleEditApplicaton(appId) {

        const app = this.state.applications.find(f => f.appId === appId)

        // Read environments
        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch(`/h1f2d1i1d2b/admin/environments/${appId}`, requestOptions)
            .then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                this.setState({
                    tableEnvironments: {
                        title: "Ambientes",
                        columns: {
                            title: ["Ambiente", "URL", "Versão", ""],
                            width: [15, 65, 10, 10],
                            align: ["left", "left", "center", "right"],
                            search: [true, true, false, false]
                        },
                        rows: this.buildGridEnvironmentRows(json),
                        pagination: true,
                        actions: [
                            { action: () => this.handleNewEnvironment(), icon: faPlus, title: "Novo URL" }
                        ]
                    },
                    environments: json
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))

        this.setState({
            modal: {
                isOpen: true, isNew: false,
                appId: app.appId, externalAppId: app.externalAppId, appName: app.appName, issuer: app.gas.issuer, subject: app.gas.subject, audience: app.gas.audience,
                userIdentification: app.userIdentification, accessTokenExpiration: app.accessTokenExpiration,
                secret: app.gas.secret, showAlert: false, alertMsg: "", alertType: ToastTypes.Info
            }
        })
    }

    handleCloseModalAlert() {
        this.setState({ modal: { ...this.state.modal, showAlert: false, alertMsg: "" } })
    }

    handleCloseModalEnvironmentAlert() {
        this.setState({ modalEnvironment: { ...this.state.modalEnvironment, showAlert: false, alertMsg: "" } })
    }

    handleNewAppId() {
        this.setState({ modal: { ...this.state.modal, appId: uuidv4() } })
    }

    handleNewSecret() {

        let length = 60, charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.#$&", retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }

        this.setState({ modal: { ...this.state.modal, secret: retVal } })
    }

    handleProfiles(e, appId) {
        e.preventDefault()

        this.props.history.push({ pathname: "/h1f2d1i1d2b/profiles", search: "id=" + appId })
    }

    handleMenu(e, appId) {
        e.preventDefault()

        this.props.history.push({ pathname: "/h1f2d1i1d2b/menu", search: "id=" + appId })
    }

    handleUsers(e, appId) {
        e.preventDefault()

        this.props.history.push({ pathname: "/h1f2d1i1d2b/users", search: "id=" + appId })
    }

    handleSaveApplication() {

        this.setState({ modal: { ...this.state.modal, alertMsg: "", showAlert: false } })

        if (!uuidValidate(this.state.modal.appId)) {
            this.setState({ modal: { ...this.state.modal, alertMsg: "AppId não é válido", showAlert: true, alertType: ToastTypes.Warning } })
            return
        }

        if (this.state.modal.appName === "" || this.state.modal.issuer === "" || this.state.modal.secret === "" || this.state.modal.subject === "" || this.state.modal.audience === "" ||
            this.state.modal.userIdentification === "") {
            this.setState({ modal: { ...this.state.modal, alertMsg: "Necessário preencher os campos", showAlert: true, alertType: ToastTypes.Warning } })
            return
        }

        if (this.state.modal.issuer.indexOf(" ") !== -1) {
            this.setState({ modal: { ...this.state.modal, alertMsg: "Issuer não pode ter espaços", showAlert: true, alertType: ToastTypes.Warning } })
            return
        }

        if (this.state.modal.secret.length < 50) {
            this.setState({ modal: { ...this.state.modal, alertMsg: "Secret tem de ter mínimo 50 caracteres", showAlert: true, alertType: ToastTypes.Warning } })
            return
        }

        const data = {
            appId: this.state.modal.appId,
            externalAppId: this.state.modal.externalAppId,
            appName: this.state.modal.appName,
            userIdentification: this.state.modal.userIdentification,
            accessTokenExpiration: this.state.modal.accessTokenExpiration,
            gas: {
                issuer: this.state.modal.issuer,
                subject: this.state.modal.subject,
                audience: this.state.modal.audience,
                secret: this.state.modal.secret
            }
        }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        this.props.dispatch(Loading(true))

        fetch("/h1f2d1i1d2b/admin/applications?isnew=" + this.state.modal.isNew, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    if (response.status === 409)
                        this.setState({ modal: { ...this.state.modal, alertMsg: "Já existe uma appId com esse UUID!", showAlert: true, alertType: ToastTypes.Danger } })
                    else
                        this.setState({ modal: { ...this.state.modal, alertMsg: "Não foi possível gravar!", showAlert: true, alertType: ToastTypes.Danger } })
                    return
                }

                let gRows = [...this.state.table.rows]
                let applications = this.state.applications
                let index = applications.findIndex(f => f.appId === this.state.modal.appId)

                if (!this.state.modal.isNew) {
                    applications[index].appId = data.appId
                    applications[index].externalAppId = data.externalAppId
                    applications[index].appName = data.appName
                    applications[index].userIdentification = data.userIdentification
                    applications[index].accessTokenExpiration = data.accessTokenExpiration
                    applications[index].gas = data.gas

                    gRows[index].columns[0].column = data.appId
                    gRows[index].columns[1].column = data.appName
                }
                else {
                    applications.push(data)

                    const newRow = {
                        id: data.appId,
                        columns: [
                            { column: data.appId },
                            { column: data.appName },
                            { column: faSync, color: "#dc3545", action: () => this.handleRefreshAppCache(data.appId) }
                        ],
                        actions: [
                            { column: faEdit, action: () => this.handleEditApplicaton(data.appId), tooltip: "Editar" },
                            { column: faAddressCard, action: (e) => this.handleProfiles(e, data.appId), tooltip: "Perfis" },
                            { column: faBars, action: (e) => this.handleMenu(e, data.appId), tooltip: "Menu" },
                            { column: faUserShield, action: (e) => this.handleUsers(e, data.appId), tooltip: "Utilizadores" }
                        ]
                    }

                    gRows = [...gRows, newRow]
                }

                this.setState({
                    modal: {
                        ...this.state.modal,
                        isOpen: false, isNew: true,
                        appId: "", externalAppId: "", appName: "", issuer: "", subject: "AccessToken", audience: "default",
                        userIdentification: "UserIdentifier", accessTokenExpiration: false,
                        secret: "", showAlert: false, alertMsg: "", alertType: ToastTypes.Info
                    },
                    applications: applications,
                    table: { ...this.state.table, rows: gRows }
                })

                this.setState({ modal: { ...this.state.modal, alertMsg: "Aplicação gravada", showAlert: true, alertType: ToastTypes.Success } })
                this.props.dispatch(Loading(false))
            })
            .catch((e) => { this.setState({ modal: { ...this.state.modal, alertMsg: "Não foi possível gravar!", showAlert: true, alertType: ToastTypes.Danger } }); console.error(e); this.props.dispatch(Loading(false)) })
    }

    toggleModalRefreshCache = () => {
        this.setState(prevState => { return { modalRefreshCache: { ...prevState.modalRefreshCache, isOpen: !prevState.modalRefreshCache.isOpen } } })
    }

    handleRefreshAppCache(appId) {
        const app = this.state.applications.find(f => f.appId === appId)
        this.setState({ modalRefreshCache: { isOpen: true, env: "env", appId: appId, appName: app.appName } })
    }

    RefreshAppCache() {
        var env = this.state.modalRefreshCache.env;
        var appId = this.state.modalRefreshCache.appId

        


        let appName = this.state.applications.find(f => f.appId === this.state.modalRefreshCache.appId).appName

        if (!window.confirm("A cache da aplicação \"" + appName + "\" será limpa! Deseja continuar?"))
            return;

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/h1f2d1i1d2b/admin/app-reset/" + appId+"/"+env, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível limpar a cache!", ToastTypes.Danger, true))
                    return
                }

                this.props.dispatch(Toast("Cache limpa", ToastTypes.Success, false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível limpar a cache!", ToastTypes.Danger, true)); console.error(e) })
    }

    labelEnvironment(envId) {
        return Math.abs(envId) === 1 ? "Produção" : Math.abs(envId) === 2 ? "Qualidade" : "Desenvolvimento";
    }
    labelVersion(version) {
        return version === 1 ? "Antiga" : "Atual"
    }

    handleNewEnvironment() {
        this.setState({
            modalEnvironment: {
                isOpen: true, appId: this.state.modal.appId, envId: 0, envTypeId: 1, version: 2, url: "", alertType: ToastTypes.Info, showAlert: false, alertMsg: ""
            }
        })
    }

    handleEditEnvironment(envId, appId) {

        let url = this.state.environments.find(f => f.environmentId === envId && f.appId === appId)

        this.setState({
            modalEnvironment: {
                isOpen: true, appId: appId, envId: envId, envTypeId: url.environmentTypeId, version: url.version, url: url.environmentUrl,
                alertType: ToastTypes.Info, showAlert: false, alertMsg: ""
            }
        })
    }

    handleSaveEnvironment() {

        const modal = this.state.modalEnvironment

        if (modal.url === "")
            return this.setState({ modalEnvironment: { ...this.state.modalEnvironment, alertMsg: "Url inválido", showAlert: true, alertType: ToastTypes.Warning } })

        if (modal.envId === 0 && this.state.environments.findIndex(f => f.environmentUrl.toLowerCase() === modal.url.toLowerCase()) !== -1)
            return this.setState({ modalEnvironment: { ...this.state.modalEnvironment, alertMsg: "Url já existente", showAlert: true, alertType: ToastTypes.Warning } })

        if (modal.envId === 0 && modal.version === 1) { //new url from old version
            if (this.state.environments.findIndex(f => f.version === 1 && f.environmentTypeId === modal.envTypeId) !== -1)
                return this.setState({ modalEnvironment: { ...this.state.modalEnvironment, alertMsg: "Já existe um url para esse ambiente", showAlert: true, alertType: ToastTypes.Warning } })
        }

        const data = {
            appId: modal.appId,
            environmentId: modal.version === 1 ? -1 * modal.envTypeId : modal.envId,
            environmentTypeId: modal.envTypeId,
            environmentUrl: modal.url,
            version: modal.version
        }

        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        this.props.dispatch(Loading(true))

        fetch(`/h1f2d1i1d2b/admin/environments/${data.appId}`, requestOptions)
            .then(response => {
                if (response.status !== 200)
                    return this.setState({ modal: { ...this.state.modal, alertMsg: "Não foi possível gravar!", showAlert: true, alertType: ToastTypes.Danger } })

                return response.json()
            })
            .then(json => {

                const env = [...this.state.environments]

                if (modal.envId === 0)
                    env.push({
                        environmentId: parseInt(json),
                        appId: data.appId,
                        environmentTypeId: data.environmentTypeId,
                        environmentUrl: data.environmentUrl,
                        version: data.version
                    })
                else {
                    let update = env.find(f => f.environmentId === modal.envId)
                    update.environmentTypeId = data.environmentTypeId
                    update.environmentUrl = data.environmentUrl
                    update.version = data.version
                }

                this.setState({
                    environments: env,
                    modalEnvironment: { ...modal, isOpen: false },
                    tableEnvironments: { ...this.state.tableEnvironments, rows: this.buildGridEnvironmentRows(env) }
                })

                this.props.dispatch(Loading(false))
            })
            .catch(_ => {
                this.setState({ modalEnvironment: { ...this.state.modalEnvironment, alertMsg: "Não foi possível gravar!", showAlert: true, alertType: ToastTypes.Danger } });
                this.props.dispatch(Loading(false))
            })
    }

    handleDelEnvironment(envId, appId) {
        let url = this.state.environments.find(f => f.environmentId === envId && f.appId === appId)

        if (!window.confirm(`Eliminar o url "${url.environmentUrl}"?`))
            return;

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch(`/h1f2d1i1d2b/admin/environments/${appId}/${envId}`, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível remover!", ToastTypes.Danger, true))
                    return
                }

                let environments = this.state.environments.filter(f => f.environmentId !== envId)

                this.setState({
                    environments: environments,
                    tableEnvironments: { ...this.state.tableEnvironments, rows: this.buildGridEnvironmentRows(environments) }
                })

                this.props.dispatch(Toast("Url removido", ToastTypes.Success, false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível remover!", ToastTypes.Danger, true)); console.error(e) })
    }

    render() {
        if (!this.state.isAuth)
            return (<></>)

        return (
            <div className="right-panel" style={{ marginLeft: 0, marginTop: 0 }}>
                <div className={"content"} style={{ backgroundColor: "white" }}>
                    <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                        actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} urlSearch={this.props.urlSearch} liveSearch={true} />

                    <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle} className="modal-lg">
                        <ModalHeader toggle={this.toggle}>Aplicação</ModalHeader>
                        <ModalBody>
                            <div className={"customAlert " + this.state.modal.alertType} style={this.state.modal.showAlert ? { display: "inherit" } : { display: "none" }}>
                                <button type="button" className="close" onClick={this.handleCloseModalAlert} aria-label="Close"><span aria-hidden="true">×</span></button>
                                {this.state.modal.alertMsg}
                            </div>
                            <Row>
                                <Col style={{ textAlign: "left" }}>
                                    <Label>AppId*
                                        <span style={{ paddingLeft: 5 + "px", display: !this.state.modal.isNew ? "none" : null, fontSize: 0.7 + "rem" }}>
                                            (<Link to="#" onClick={() => this.handleNewAppId()}>Gerar</Link>)
                                        </span>
                                    </Label>
                                    <Input id="AppId" type="text" value={this.state.modal.appId} autoComplete="off" maxLength="50"
                                        disabled={!this.state.modal.isNew} onChange={(e) => this.setState({ modal: { ...this.state.modal, appId: e.target.value } })} />
                                </Col>
                                <Col style={{ textAlign: "left" }}>
                                    <Label>AppId Externo</Label>
                                    <Input id="ExternalAppId" type="text" value={this.state.modal.externalAppId} autoComplete="off" maxLength="100"
                                        onChange={(e) => this.setState({ modal: { ...this.state.modal, externalAppId: e.target.value } })} />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "left" }}>
                                    <Label>Nome*</Label>
                                    <Input id="appName" type="text" value={this.state.modal.appName} autoComplete="off" maxLength="100"
                                        onChange={(e) => this.setState({ modal: { ...this.state.modal, appName: e.target.value } })} />
                                </Col>
                                <Col style={{ textAlign: "left" }}>
                                    <Label>Issuer*</Label>
                                    <Input id="issuer" type="text" value={this.state.modal.issuer} autoComplete="off" maxLength="50"
                                        onChange={(e) => this.setState({ modal: { ...this.state.modal, issuer: e.target.value } })} />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "left" }}>
                                    <Label>Secret*
                                        <span style={{ paddingLeft: 5 + "px", fontSize: 0.7 + "rem" }}>
                                            (<Link to="#" onClick={() => this.handleNewSecret()}>Gerar</Link>)
                                        </span>
                                    </Label>
                                    <Input id="secret" type="text" value={this.state.modal.secret} autoComplete="off" maxLength="100"
                                        onChange={(e) => this.setState({ modal: { ...this.state.modal, secret: e.target.value } })} />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "left", marginTop: `${5}px` }}>
                                    <McdTable title={this.state.tableEnvironments.title} columns={this.state.tableEnvironments.columns} rows={this.state.tableEnvironments.rows}
                                        pagination={this.state.tableEnvironments.pagination} actions={this.state.tableEnvironments.actions} filters={this.state.tableEnvironments.filters} />
                                </Col>
                            </Row>

                            <Modal isOpen={this.state.modalEnvironment.isOpen} toggle={this.toggleEnvironment}>
                                <ModalHeader toggle={this.toggleEnvironment}></ModalHeader>
                                <ModalBody>
                                    <div className={"customAlert " + this.state.modalEnvironment.alertType} style={this.state.modalEnvironment.showAlert ? { display: "inherit" } : { display: "none" }}>
                                        <button type="button" className="close" onClick={this.handleCloseModalEnvironmentAlert} aria-label="Close"><span aria-hidden="true">×</span></button>
                                        {this.state.modalEnvironment.alertMsg}
                                    </div>
                                    <Row>
                                        <Col>
                                            <Label>Ambiente</Label>
                                            <Input id="env" type="select" value={this.state.modalEnvironment.envTypeId} disabled={this.state.modalEnvironment.envId !== 0}
                                                onChange={e => this.setState({ modalEnvironment: { ...this.state.modalEnvironment, envTypeId: parseInt(e.target.value) } })}>
                                                <option value="1">Produção</option>
                                                <option value="2">Qualidade</option>
                                                <option value="3">Desenvolvimento</option>
                                            </Input>
                                        </Col>
                                        <Col>
                                            <Label>Versão</Label>
                                            <Input id="version" type="select" value={this.state.modalEnvironment.version} disabled={this.state.modalEnvironment.envId !== 0}
                                                onChange={e => this.setState({ modalEnvironment: { ...this.state.modalEnvironment, version: parseInt(e.target.value) } })}>
                                                <option value="2">Atual</option>
                                                <option value="1">Antiga</option>
                                            </Input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>Url</Label>
                                            <Input id="url" type="url" value={this.state.modalEnvironment.url} autoComplete="off" maxLength="1000"
                                                onChange={e => this.setState({ modalEnvironment: { ...this.state.modalEnvironment, url: e.target.value } })} />
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={this.handleSaveEnvironment}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                                </ModalFooter>
                            </Modal>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.handleSaveApplication}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.modalRefreshCache.isOpen} toggle={this.toggleModalRefreshCache} id="ModalRefreshCache">
                        <ModalHeader toggle={this.toggleModalRefreshCache}>Refresh <b>{this.state.modalRefreshCache.appName}</b> Cache...</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col style={{ textAlign: "left" }} xs="12">
                                    <Input type="select" id="ddlPasteEnvironment" onChange={(e) => this.setState({ modalRefreshCache: { ...this.state.modalRefreshCache, env: e.target.value } })}>
                                        <option value="app">Cache da Aplicação</option>
                                        <option value="env">Cache de Ambientes</option>
                                        <option value="token">Reset App Refresh Tokens</option>
                                    </Input>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter style={{ display: "block" }}>
                            <Button color="primary" style={{ float: "right" }}
                                onClick={this.RefreshAppCache}><FontAwesomeIcon icon={faSync} /> Limpar</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return { access_token: state.access_token }
}

export default connect(mapStateToProps)(Applications)