import React, { Component } from "react"
import { connect } from "react-redux"
import { GetParameter, Toast, ToastTypes, Loading } from "../components/global/Utils"
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Row, Col, Input, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap'
import JSONInput from "react-json-editor-ajrm"
import locale from "react-json-editor-ajrm/locale/en"
import AdminNavbar from './AdminNavbar';

class AdminMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            jsonMenu: { menu: [] },
            jsonTheme: "light_mitsuketa_tribute",
            jsonError: false,
            appName: "",
            Environment: sessionStorage.getItem("environment") || 1,
            modalCopyEnv: { isOpen: false, from: null, to: null }
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSaveMenu = this.handleSaveMenu.bind(this)
        this.handleChangeEnvironment = this.handleChangeEnvironment.bind(this)
        this.handleCopyMenu = this.handleCopyMenu.bind(this)
        this.handlePasteMenu = this.handlePasteMenu.bind(this)

    }

    componentDidMount() {
        this.getMenu()
    }

    getMenu(env) {

        if (env == null)
            env = this.state.Environment


        let appId = GetParameter("id")
        if (appId == null || this.props.access_token == null) {
            this.props.history.replace("/h1f2d1i1d2b")
            return;
        }

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/h1f2d1i1d2b/admin/menu/" + appId + "/" + env, requestOptions)
            .then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    appName: json.appName,
                    jsonMenu: JSON.parse(json.json)
                })

                this.props.dispatch(Loading(false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível obter o menu", ToastTypes.Danger, false)); console.error(e); })
    }

    toggleModalCopyPaste = () => {
        this.setState(prevState => { return { modalCopyEnv: { ...prevState.modalCopyEnv, isOpen: !prevState.modalCopyEnv.isOpen } } })
    }

    handleChangeEnvironment(e) {


        this.setState({ Environment: e })

        this.getMenu(e)
    }


    handleCopyMenu() {

        this.setState({ modalCopyEnv: { isOpen: true, from: this.state.Environment, to: (this.state.Environment === 1 ? 2 : 1) } })
    }


    handlePasteMenu() {

        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))
        
        fetch("/h1f2d1i1d2b/admin/menu/" + GetParameter("id") + "/" + String(this.state.modalCopyEnv.from) + "/" + String(this.state.modalCopyEnv.to), requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível gravar!", ToastTypes.Danger, true))
                    return
                }

                this.setState({
                    modalCopyEnv: {
                        ...this.state.modalCopyEnv,
                        isOpen: false, from: null, to: null
                    }
                })

                this.props.dispatch(Toast("Menu copiado", ToastTypes.Success, false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível copiar!", ToastTypes.Danger, true)); console.error(e) })
    }


    handleChange(e) {

        if (e.error === false) {
            this.setState({
                jsonError: false,
                jsonMenu: e.jsObject
            })

            return
        }

        this.setState({ jsonError: true })
    }

    handleSaveMenu() {

        let data = JSON.stringify(this.state.jsonMenu)
        if (data.length < 11)
            return

        data = data.substring(8)
        data = data.substring(0, data.length - 1)

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        this.props.dispatch(Loading(true))

        fetch("/h1f2d1i1d2b/admin/menu/" + GetParameter("id") + "/" + this.state.Environment, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível gravar!", ToastTypes.Danger, true))
                    return
                }

                this.props.history.push("/h1f2d1i1d2b")
                this.props.dispatch(Toast("Menu gravado", ToastTypes.Success, false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível gravar!", ToastTypes.Danger, true)); console.error(e) })
    }

    render() {

        if (this.state.appName === "")
            return (<></>)

        return (
            <div className="right-panel" style={{ marginLeft: 0, marginTop: 0 }}>
                <div className={"content"} style={{ backgroundColor: "white" }}>
                    <AdminNavbar
                        environment={this.state.Environment}
                        onEnvironmentChange={this.handleChangeEnvironment}
                        onCopyProfiles={this.handleCopyProfiles}
                        navTitle={" "}
                        id={GetParameter("id")}
                    />


                    <Row style={{ paddingBottom: 10 + "px" }}>
                        <Col>
                            <h3 style={{ color: "rgb(115, 135, 156)" }}>{this.state.appName} - Menu</h3>
                        </Col>
                        <Col>
                            <Button color="primary" style={{ float: "right" }} onClick={this.handleSaveMenu} disabled={this.state.jsonError}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <JSONInput id="a_unique_id" placeholder={this.state.jsonMenu} locale={locale} style={{ container: { border: "1px solid lightgrey" } }}
                                theme={this.state.jsonTheme} width="100%" onChange={this.handleChange} />
                        </Col>
                    </Row>

                    <Modal isOpen={this.state.modalCopyEnv.isOpen} toggle={this.toggleModalCopyPaste} id="ModalCopyPaste">
                        <ModalHeader toggle={this.toggleModalCopyPaste}>Copiar menu para...</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col style={{ textAlign: "left" }} xs="12">
                                    <Input type="select" id="ddlPasteEnvironment" onChange={(e) => this.setState({ modalCopyEnv: { ...this.state.modalCopyEnv, to: e.target.value } })}>
                                        <option value="1" disabled={this.state.modalCopyEnv.from === 1}>Produção</option>
                                        <option value="2" disabled={this.state.modalCopyEnv.from === 2}>Qualidade</option>
                                        <option value="3" disabled={this.state.modalCopyEnv.from === 3}>Desenvolvimento</option>
                                    </Input>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter style={{ display: "block" }}>
                            <Button color="primary" style={{ float: "right" }}
                                onClick={this.handlePasteMenu}><FontAwesomeIcon icon={faSave} />Copiar</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return { access_token: state.access_token }
}

export default connect(mapStateToProps)(AdminMenu)