import React, { useState, useRef, useEffect } from "react"
import { Col, Container, Row, Pagination, PaginationItem, PaginationLink, Input, CustomInput, Button, Collapse, Table } from "reactstrap"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { faSearch, faEraser, faFilter } from "@fortawesome/free-solid-svg-icons";

const McdTable = (props) => {
    const [constructorHasRun, setConstructorHasRun] = useState(false);
    const [pagePrevious, setPagePrevious] = useState(false)
    const [pageCurrent, setPageCurrent] = useState(1)
    const [pageNext, setPageNext] = useState(true)
    const [showPagination, setShowPagination] = useState(props.pagination)
    const [showSearchBox, setShowSearchBox] = useState(false)
    const [textToSearch, setTextToSearch] = useState("")
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipTimeoutRef = useRef(null);
    const refSearchBox = useRef(null)
    const [collapse, setCollapse] = useState(false)
    const [data, setData] = useState([])
    const [liveSearch] = useState(props.liveSearch || false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const getRowsPerPage = () => {

        let hHeader = 0, hContentT = 0, hContentB = 0;
        if (document.getElementsByClassName('header')[0] !== undefined)
            hHeader = getComputedStyle(document.getElementsByClassName('header')[0]).height.replace('px', '') * 1

        if (document.getElementsByClassName('content').length !== 0) {
            hContentT = getComputedStyle(document.getElementsByClassName('content')[0]).paddingTop.replace('px', '') * 1
            hContentB = getComputedStyle(document.getElementsByClassName('content')[0]).paddingBottom.replace('px', '') * 1
        }

        const hContentTile = (props.title !== "" && props.columns.search.filter(search => search).length === 0 && props.actions.length === 0) ? 0 : 42
        const hGridHeader = 45
        const hRow = 35
        const hMisc = hRow + 15
        const hPagination = 38
        const availableHeight = window.innerHeight - hHeader - hContentT - hContentB - hContentTile - hMisc - hGridHeader - hPagination

        return Math.floor(availableHeight / hRow) + 1
    }
    const rowsPerPage = getRowsPerPage()

    const constructor = () => {
        if (constructorHasRun || !showPagination) return;

        let page = getParameter("page")
        if (isNaN(page) || page === "") page = 1
        page = page * 1

        // validar se page é valido
        let rows = props.rows.filter((filter, current) => { return (current >= (rowsPerPage * (page - 1)) && current < ((rowsPerPage * (page - 1)) + rowsPerPage)) }).length
        if (rows === 0) {
            setPagePrevious(false)
            setPageCurrent(1)
        }
        else {
            // tem
            setPagePrevious(page > 1)
            setPageCurrent(page)
        }

        setConstructorHasRun(true);
    }

    const gridTitle = () => {
        if (props.title === "")
            return (<></>)

        return (
            <nav className="navbar content-title">
                <h3 style={{ color: "#73879C", marginBottom: 0 }}>{props.title}</h3>
                <form className="form-inline">
                    <ul className="nav">
                        {
                            props.columns.search.includes(true) ?
                                <li id="liSearchBtn">
                                    <div className="input-group">
                                        <input id={"txtSearch" + Math.random().toString(16).slice(-4)} type="text" ref={refSearchBox} className={classNames("form-control border-right-0", !showSearchBox ? "d-none" : "")} placeholder="procurar" autoComplete="off"
                                            onKeyDown={handleTextSearchKeyDown} onKeyUp={handleTextSearchKeyUp} onBlur={handleTextSearchBlur}
                                            style={{ width: 20 + "vw", maxWidth: 200 + "px", height: 30 + "px", borderRadius: 5 + "px 0 0 5px" }} />
                                        <Link to="#" onClick={handleTextSearchShow()} className={classNames("icon-sub-header form-control", !showSearchBox ? "hide-search" : "")} title="Procurar">
                                            <FontAwesomeIcon icon={faSearch} className="text-muted" style={{ textAlign: "center", verticalAlign: "middle" }} />
                                        </Link>
                                    </div>
                                </li>
                                : null
                        }

                        <li style={!showSearchBox ? { display: "inherit" } : { display: "none" }}>
                            {
                                props.actions.filter(item => item !== null).map((action, index) => {
                                    return (
                                        <ul key={index} className="nav">
                                            <li>
                                                <div className="input-group" title={action.tooltip !== undefined ? action.tooltip : null}>
                                                    {
                                                        typeof action.action === "function" ?
                                                            <Link to={{ pathname: document.location.pathname, search: props.urlSearch }}
                                                                onClick={action.action} className="icon-sub-header" title={action.title} replace>
                                                                <FontAwesomeIcon icon={action.icon} className="text-muted" style={{ textAlign: "center", verticalAlign: "middle" }} />
                                                            </Link>
                                                            :
                                                            action.action === "filter" ?
                                                                <Link to={{ pathname: document.location.pathname, search: props.urlSearch }}
                                                                    onClick={() => setCollapse(!collapse)} className="icon-sub-header" title="Filtros" replace>
                                                                    <FontAwesomeIcon icon={action.icon} className="text-muted" style={{ textAlign: "center", verticalAlign: "middle" }} />
                                                                </Link>
                                                                :
                                                                <Link to={action.action} className="icon-sub-header" title={action.title}>
                                                                    <FontAwesomeIcon icon={action.icon} className="text-muted" style={{ textAlign: "center", verticalAlign: "middle" }} />
                                                                </Link>
                                                    }

                                                </div>
                                            </li>
                                        </ul>
                                    )
                                })
                            }
                        </li>
                    </ul>
                </form>
            </nav>
        )
    }

    const Header = () => {
        return props.columns.title.map((column, index) => {
            return (
                <th key={index}
                    className={classNames(Align(props.columns.align[index]))}
                    style={{ fontWeight: "bold", minHeight: 35 + "px", height: 35 + "px", width: props.columns.width[index] + "%", paddingTop: 0, paddingBottom: 0, verticalAlign: "top" }}>
                    <span className="d-sm-block">{column}</span>
                </th>
            )
        })
    }

    const Filter = () => {
        if (props.filters === undefined) return (<></>)

        return (
            <>
                <Collapse isOpen={!collapse}>
                    <Container style={{ backgroundColor: "#E6E9ED", paddingTop: 10 + "px", paddingBottom: 10 + "px", padding: 5 + "px", fontSize: 12 + "px" }}>
                        <Row style={{ display: "flex", marginLeft: 0, marginRight: 0 }}>
                            {
                                props.filters.filter(f => f !== null).map(item => {

                                    if ((item.type === "text" && data.length !== 0 && data.find(x => x.id === item.id).values !== "") ||
                                        (item.type === "list" && item.multiple && data.length !== 0 && data.find(x => x.id === item.id).values.length !== 0) ||
                                        (item.type === "list" && !item.multiple && data.length !== 0 && data.find(x => x.id === item.id).values !== "") ||
                                        (item.type === "switch"))

                                        return (
                                            <div key={item.id} style={{
                                                padding: 3 + "px", border: "1px solid #c3c3c3", width: 140 + "px", borderRadius: 0.25 + "rem", textAlign: "center",
                                                backgroundColor: "#f8f8f9", marginRight: 10 + "px", marginTop: 5 + "px"
                                            }}>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: 80 + "%" }}>{item.label}</div>
                                                    {
                                                        item.type === "list" && item.multiple ?
                                                            <span className="badge badge-secondary">{getBadgeList(item.id)}</span>
                                                            :
                                                            item.type === "switch" ?
                                                                <span className="badge badge-secondary">{getBadgeSwitch(item.id)}</span>
                                                                : null
                                                    }
                                                </div>
                                            </div>
                                        )

                                    return null
                                })
                            }
                        </Row>
                    </Container>
                </Collapse>
                <Collapse isOpen={collapse}>
                    <Container style={{ backgroundColor: "#E6E9ED", paddingTop: 10 + "px", paddingBottom: 10 + "px" }}>
                        <Row className="grid-filter">
                            {
                                props.filters.filter(f => f !== null).map((item, index) =>
                                    <Col key={index} xs="6" sm="4" md="3" style={{ marginBottom: 10 + "px" }}>
                                        <label>{item.label}</label>
                                        {renderFilter(item)}
                                    </Col>
                                )
                            }
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <Button color="secondary" style={{ margin: 4 + "px" }} size="sm" onClick={handleFilterReset}>
                                    <FontAwesomeIcon icon={faEraser} style={{ marginRight: 6 + "px" }} />Limpar
                            </Button>
                                <Button color="primary" style={{ margin: 4 + "px" }} size="sm" onClick={handleApplyFilter}>
                                    <FontAwesomeIcon icon={faFilter} style={{ marginRight: 6 + "px" }} />Aplicar
                            </Button>
                            </Col>
                        </Row>
                    </Container>
                </Collapse>
            </>
        )
    }

    const renderFilter = (item) => {
        switch (item.type) {
            case "text":
                return (
                    <Input type="text" id={item.id} defaultValue={item.data} autoComplete="off" />
                )
            case "list":
                return (
                    <Input type="select" id={item.id} multiple={item.multiple}
                        defaultValue={item.multiple ? item.data.filter(v => v.selected).map(v => v.value) : item.data.find(v => v.selected).value}
                        onChange={e => {
                            if (item.child_id === undefined)
                                return;

                            let aux = []
                            for (var i = 0; i < e.target.selectedOptions.length; i++) {
                                aux.push(parseInt(e.target.selectedOptions[i].value))
                            }

                            document.getElementById(item.child_id).value = ""
                            props.filters.filter(f => f !== null).filter(x => item.child_id === x.id).forEach(i => i.values = aux)
                            props.filters.filter(f => f !== null).filter(x => item.child_clean.find(c => c === x.id) !== undefined).forEach(i => i.values = [])

                            forceUpdate()
                        }}>
                        {
                            item.data.filter(f => {
                                if (item.values === undefined)
                                    return true;

                                return item.values.find(x => x === f.parent) !== undefined
                            }).map(v => {
                                return (<option key={v.value} value={v.value}>{v.text}</option>)
                            })
                        }
                    </Input>
                )
            case "switch":
                return (
                    <CustomInput type="switch" id={item.id} defaultChecked={item.data} />
                )
            default:
        }
    }

    useEffect(() => {
        if (liveSearch) {
            const handleKeyDown = (event) => {
                if (["INPUT", "TEXTAREA"].includes(document.activeElement.tagName)) {
                    return; 
                }

                const validKey = /^[a-zA-Z0-9._-]$/;
                if (event.key === "Backspace") {
                    setTextToSearch("");
                    setData(props.rows); 
                    setShowPagination(props.pagination);
                    setShowTooltip(false);
                } else if (validKey.test(event.key)) {  
                    const newTextToSearch = textToSearch + event.key;
                    setTextToSearch(newTextToSearch);
                    setShowTooltip(true);

                    
                    if (tooltipTimeoutRef.current) {
                        clearTimeout(tooltipTimeoutRef.current);
                    }
                    tooltipTimeoutRef.current = setTimeout(() => {
                        setShowTooltip(false);
                    }, 2000);

                    
                    const filteredRows = props.rows.filter((row) =>
                        row.columns.some((col, index) =>
                            props.columns.search[index] &&
                            col.column.toString().toLowerCase().includes(newTextToSearch.toLowerCase())
                        )
                    );

                    setShowPagination(props.pagination && filteredRows.length > rowsPerPage);
                    setData(filteredRows);
                }
            };

            window.addEventListener("keydown", handleKeyDown);

            return () => {
                window.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [liveSearch, textToSearch, props.rows, props.columns.search, props.pagination, rowsPerPage]);



    const Rows = () => {
        
        if (props.rows === undefined || rowsPerPage === undefined)
            return (<></>)

        return props.rows
            .filter((search) => {
                if (textToSearch === "") return true

                return search.columns.filter((col, index) => {
                    return props.columns.search[index] === true && col.column.toString().toLowerCase().indexOf(textToSearch.toLowerCase()) !== -1
                }).length !== 0
            })
            .filter((filter, current) => {
                if (!showPagination) return true

                return current >= (rowsPerPage * (pageCurrent - 1)) && current < ((rowsPerPage * (pageCurrent - 1)) + rowsPerPage)
            }).map(row => {
                const maxIndex = row.columns.length
                const row_tooltip = row.tooltip !== undefined ? row.tooltip : ""

                return (
                    <tr key={row.id} className="grid-rows" style={row.row_clickable ? cssRowClickable : cssRow} data-id={row.id}
                        title={row_tooltip} onClick={(row.row_clickable && row.row_action !== undefined) ? row.row_action : null}>
                        {
                            row.columns.map((col, index) => {
                                const col_tooltip = col.tooltip !== undefined ? col.tooltip : null

                                return (
                                    <td key={index}
                                        style={{ width: props.columns.width[index] + "%", color: col.color !== undefined ? col.color : null, paddingTop: 0, paddingBottom: 0, verticalAlign: "inherit" }}
                                        className={classNames(Align(props.columns.align[index]))}
                                        title={col_tooltip}>{

                                            col.action !== undefined ? // has action (path or function)
                                                typeof col.action === "function" ?
                                                    <Link to="#" onClick={col.action}>{!isNormalColumn(col.column) ? <FontAwesomeIcon icon={col.column} color={col.color} /> : col.column}</Link>
                                                    :
                                                    <Link to={col.action}>{!isNormalColumn(col.column) ? <FontAwesomeIcon icon={col.column} color={col.color} /> : col.column}</Link>
                                                : // is only a normal column
                                                !isNormalColumn(col.column, col.columnType) ?
                                                    (col.columnType === "image") ?
                                                        <img src={col.column} alt="" height="25" width="25" style={{ margin: 5 + "px" }} />
                                                        :
                                                        <FontAwesomeIcon icon={col.column} color={col.color} />
                                                    :
                                                    col.column
                                        }</td>
                                )
                            })
                        }
                        { // ACTIONS
                            row.actions !== undefined ?
                                <td key="actions"
                                    style={{ width: props.columns.width[maxIndex] + "%", paddingTop: 0, paddingBottom: 0, verticalAlign: "inherit" }}
                                    className={classNames(Align(props.columns.align[maxIndex]))}>
                                    {
                                        row.actions.filter(item => item !== null).map((item, index) => {
                                            return (
                                                item.action !== undefined ? // has action (path or function)
                                                    typeof item.action === "function" ?
                                                        <Link key={index} to="#" className="iconHoverCustom" onClick={item.action} style={cssActionBetween}>{!isNormalColumn(item.column) ? <FontAwesomeIcon  icon={item.column} title={item.tooltip}
                                                            color={item.color === undefined ? "#4fa570" : item.color} /> : item.column}</Link>
                                                        :
                                                        <Link key={index} to={item.action} style={cssActionBetween}>{!isNormalColumn(item.column) ? <FontAwesomeIcon icon={item.column} title={item.tooltip}
                                                            color={item.color === undefined ? "#4fa570" : item.color} /> : item.column}</Link>
                                                    : // is only a normal column
                                                    !isNormalColumn(item.column) ?
                                                        <FontAwesomeIcon key={index} icon={item.column} color={item.color === undefined ? "#4fa570" : item.color} title={item.tooltip} />
                                                        :
                                                        item.column

                                            )
                                        })
                                    }</td>
                                :
                                <></>
                        }
                    </tr>
                )
            })
    }
    useEffect(() => {
        setShowPagination(props.pagination && props.rows.length > rowsPerPage);
    }, [props.pagination, props.rows.length, rowsPerPage]);

    useEffect(() => {
        if (props.rows.length > 0) {
            setShowPagination(props.pagination && props.rows.length > rowsPerPage);
            setData(props.rows.slice(0, rowsPerPage)); 
        }
    }, [props.rows, rowsPerPage, props.pagination]);
    const GridPagination = () => {
    if (!showPagination || props.rows.length <= rowsPerPage) return null;

    const totalPages = Math.ceil(props.rows.length / rowsPerPage);

    return (
        <td colSpan={props.columns.width.length} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Pagination aria-label="Page navigation">
                <PaginationItem>
                    <PaginationLink onClick={handleChangePage(pageCurrent-1)} disabled={!pagePrevious}>Anterior</PaginationLink>
                </PaginationItem>
                
                {[...Array(totalPages)].map((_, pageIndex) => {
                    const pageNum = pageIndex + 1;
                    return (
                        <PaginationItem key={pageNum} active={pageNum === pageCurrent}>
                            <PaginationLink onClick={handleChangePage(pageNum)}>
                                {pageNum}
                            </PaginationLink>
                        </PaginationItem>
                    );
                })}

                <PaginationItem>
                    <PaginationLink onClick={handleChangePage(pageCurrent + 1)} disabled={!pageNext}>Seguinte</PaginationLink>
                </PaginationItem>
            </Pagination>
        </td>
    );
};




    constructor();

    // validar se tem mais paginas
    let show = props.rows.filter((search) => {
        if (textToSearch === "") return true

        return search.columns.filter((col, index) => {
            return props.columns.search[index] === true && col.column.toString().toLowerCase().indexOf(textToSearch.toLowerCase()) !== -1
        }).length !== 0
    }).filter((filter, current) => { return (current >= (rowsPerPage * (pageCurrent - 1)) && current < ((rowsPerPage * (pageCurrent - 1)) + rowsPerPage)) }).length === rowsPerPage

    if (show !== pageNext)
        setPageNext(show)

    const handleChangePage = (pageNumber) => (event) => {
        event.preventDefault();

        const totalPages = Math.ceil(props.rows.length / rowsPerPage);

        // Ensure the new page is within valid range
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setPageCurrent(pageNumber);
            setPagePrevious(pageNumber > 1);
            setPageNext(pageNumber < totalPages);

            // Update displayed data for the selected page
            const startIdx = (pageNumber - 1) * rowsPerPage;
            setData(props.rows.slice(startIdx, startIdx + rowsPerPage));

            // Update URL with the selected page
            window.history.pushState(btoa(pageNumber), null, replaceUrlParam(document.location.href, 'page', pageNumber));
        }
    };

    const handleTextSearchShow = () => evt => {
        evt.preventDefault()

        setShowSearchBox(!showSearchBox)
        setTimeout(function () {
            refSearchBox.current.focus()
        }, 200)
    }
    const handleTextSearchKeyDown = evt => {
        if (evt.keyCode === 13) {
            evt.preventDefault();
        }
    }
    const handleTextSearchKeyUp = evt => {
        if (liveSearch || evt.keyCode === 13) { 
            if (refSearchBox.current.value !== textToSearch) {
                setTextToSearch(refSearchBox.current.value);

                setPagePrevious(0);
                setPageCurrent(1);
                window.history.pushState(btoa(1), null, replaceUrlParam(document.location.href, 'page', 1));

                if (refSearchBox.current.value === "") {
                    setShowPagination(props.pagination);
                    return;
                }

                let p1 = props.rows.filter((search) => {
                    if (refSearchBox.current.value === "") return true;

                    return search.columns.filter((col, index) => {
                        return props.columns.search[index] === true && col.column.toString().toLowerCase().indexOf(refSearchBox.current.value.toLowerCase()) !== -1;
                    }).length !== 0;
                });

                setShowPagination(props.pagination && p1.length > rowsPerPage);
            }
        }
    }
    const handleTextSearchBlur = evt => {
        if (textToSearch === "")
            setShowSearchBox(!showSearchBox)
    }

    const handleFilterReset = evt => {
        evt.preventDefault()

        props.filters.filter(f => f !== null).forEach(item => {
            const element = document.getElementById(item.id)

            switch (item.type) {
                case "text":
                    element.value = item.data
                    break
                case "list":
                    if (!item.multiple)
                        element.value = item.data.find(v => v.selected).value
                    else
                        item.values = undefined

                    for (let i = 0; i < element.length; i++) {
                        element[i].selected = item.data.find((v, index) => index === i && v.selected) === undefined ? false : true
                    }
                    break
                case "switch":
                    element.checked = item.data
                    break
                default:
            }

        })

        forceUpdate()
    }
    const handleApplyFilter = evt => {
        evt.preventDefault()

        let update = data

        const result = props.filters.filter(f => f !== null).map(item => {
            const element = document.getElementById(item.id)

            if (item.type === "switch") {
                update = update.filter(x => x.id !== item.id) // remove item
                update.push({ id: item.id, values: element.checked })

                return element.checked
            }

            if (item.type === "list" && item.multiple) {
                let aux = []
                for (var i = 0; i < element.selectedOptions.length; i++) {
                    aux.push(element.selectedOptions[i].value)
                }

                update = update.filter(x => x.id !== item.id) // remove item
                update.push({ id: item.id, values: aux })

                return aux
            }

            // Textbox
            update = update.filter(x => x.id !== item.id) // remove item
            update.push({ id: item.id, values: element.value })

            return element.value
        })

        setPageCurrent(1)
        setPageNext(false)
        setPagePrevious(false)

        setData(update)
        setCollapse(!collapse)

        props.handlerFilter(result)
    }
    const getBadgeList = (id) => {
        const aux = data.find(item => item.id === id)

        if (aux === undefined) {
            return props.filters.filter(f => f !== null).find(item => item.id === id).data.filter(item => item.selected).length
        }

        return aux.values.length
    }
    const getBadgeSwitch = (id) => {
        const aux = data.find(item => item.id === id)

        if (aux === undefined) {
            return props.filters.filter(f => f !== null).find(item => item.id === id).data ? "ON" : "OFF"
        }

        return aux.values ? "ON" : "OFF"
    }

    if (props.title === "")
        return (<></>)

    return (
        <>
            {showTooltip && (
                <div className="tooltipCustom">
                        {textToSearch}
                    </div>
                )}
            {gridTitle()}
            {Filter()}
            <Table style={{ marginTop: 10 + "px" }} borderless>
                <thead className="grid-header">
                    <tr>
                        {Header()}
                    </tr>
                </thead>
                <tbody>
                    {Rows()}
                </tbody>
                <tfoot>
                    <tr>
                        {GridPagination()}
                    </tr>
                </tfoot>
                </Table>
        </>
    )
}

const cssRow = { minHeight: 35 + "px", height: 35 + "px", borderTop: 1 + "px solid Gainsboro" }
const cssRowClickable = { minHeight: 35 + "px", height: 35 + "px", borderTop: 1 + "px solid Gainsboro", cursor: "pointer" }
const cssActionBetween = { paddingLeft: 0.4 + "em", paddingRight: 0.4 + "em" }

const Align = (type) => {
    switch (type) {
        case "left":
            return "align-self-center"
        case "right":
            return "align-self-center text-right"
        default:
            return "align-self-center text-center"
    }
}

const getParameter = (name) => {
    var url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"), results = regex.exec(url);
    if (!results || !results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const replaceUrlParam = (url, paramName, paramValue) => {
    if (paramValue == null) {
        paramValue = '';
    }
    var pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
    if (url.search(pattern) >= 0) {
        return url.replace(pattern, '$1' + paramValue + '$2');
    }
    url = url.replace(/[?#]$/, '');
    return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
}

const isNormalColumn = (column, type) => {
    return (typeof column === "string" || typeof column === "number") && type === undefined
}

export default connect()(McdTable)
